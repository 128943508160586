export default {
  getTotalDataOfForms: state => ({
    ...state.orderInformationForm,
    ...state.customerInformationForm,
    ...state.insuranceInformationForm,
    ...state.dispatchAndReturnForm,
    ...state.eventInformationForm,
    ...state.orderItemsSaleRentalTable,
    // ...state.orderItemsRentalTable,
    ...state.orderItemsOtherChargesTable,
    ...state.totalsForm,
    ...state.requiresPurchaseOrder,
  }),
}
