export default {
  order: {},
  orders: [],
  orderForm: {},
  orderInformationForm: {},
  customerInformationForm: {
    attachments: [],
  },
  insuranceInformationForm: {},
  dispatchAndReturnForm: {},
  eventInformationForm: {},
  orderItemsSaleRentalTable: {
    products: [],
  },
  orderItemsSaleRentalType: { value: 'rent' },
  // orderItemsRentalTable: {
  //   products: [],
  // },
  // orderItemsSalesTable: {
  //   products: [],
  // },
  orderItemsOtherChargesTable: {
    otherCharges: [],
  },
  totalsForm: {},
  onError: {},
  requiresPurchaseOrder: false,
  isSettledCustomer: false,
  isDispatchAndReturnAllRequiredFieldsFilled: false,
  isEventInformationRequiredFieldsFilled: false,
  isOrderInformationFormRequiredFieldsFilled: false,
  payerAccount: { value: 'renter' },
  allowToPickRentalTimesByLineItem: false,
}
