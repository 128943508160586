var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('l-table',{ref:"lTableRef",attrs:{"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumns,"table-config-options":{
      endpoint: (_vm.MODULE_NAME + "/rentalSalesList"),
    },"row-clicked":_vm.navigateToItem},scopedSlots:_vm._u([{key:"cell(order_no)",fn:function(ref){
    var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'order_id', '—'))+" ")]}},{key:"cell(state)",fn:function(ref){
    var item = ref.data.item;
return [_c('status-filter-table',{attrs:{"item":item}})]}},{key:"cell(order_date)",fn:function(ref){
    var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'order_date', '—'))+" ")]}},{key:"cell(to)",fn:function(ref){
    var item = ref.data.item;
return [_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"8px"}},[_c('feather-icon',{attrs:{"icon":_vm.checkIconType(_vm.getValueFromGivenObjectByKey(item, 'to.details.status', '&mdash;')),"size":"18"}}),_c('span',[_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'to.cust_name_dba', '—'))+" ")])],1)]}},{key:"cell(ship_by)",fn:function(ref){
    var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'shipping', '—'))+" ")]}},{key:"cell(return_by)",fn:function(ref){
    var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'return', '—'))+" ")]}},{key:"manage-monthly-date",fn:function(){return [_c('manage-monthly-date')]},proxy:true},{key:"status-filter-tab",fn:function(){return [_c('status-filter-tab')]},proxy:true},{key:"filter",fn:function(){return [_c('status-filter-color-key',{staticClass:"ml-auto"})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }