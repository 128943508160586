import mainStore from '@/store'
import rentalSalesModule from '@/store/main/orders/new-rental-sales'
import attachmentModule from '@/store/main/customer/attachment'
import catalogModul from '@/store/main/catalog'

export default function config() {
  // Buttons
  const FORM_BUTTON_TITLE_SAVE_AS_DRAFT = 'Save as Draft'
  const DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT = 'draft'
  const DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PLACE_ORDER = 'place_order'
  const DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL = 'send_email'
  const DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_HOLD_STOCKS = 'hold_stocks'

  // Constants
  const MODULE_NAME = 'rental-sales'
  const MODULE_NAME_ATTACHMENT = 'attachment'
  const MODULE_NAME_CLONE = 'cloneData'
  const MODULE_NAME_CATALOG = 'catalog'

  const store = mainStore
  const catalog = catalogModul
  const model = rentalSalesModule
  const attachmentModel = attachmentModule
  const CREATE_PAGE_TITLE = 'New Sales/Rental Quote'

  const tableColumns = [
    {
      key: 'order_no',
      label: 'Order No.',
      thStyle: {
        width: '9%',
      },
      isSortable: true,
    },
    {
      key: 'state',
      label: 'State',
      thStyle: {
        width: '13%',
      },
      isSortable: true,
    },
    {
      key: 'reference',
      label: 'Reference',
      thStyle: {
        width: '12%',
      },
      isSortable: true,
    },
    {
      key: 'order_date',
      label: 'Order Date',
      thStyle: {
        width: '10%',
      },
      isSortable: true,
    },
    {
      key: 'to',
      label: 'To',
      isSortable: true,
      thStyle: {
        width: '36%',
      },
    },
    {
      key: 'ship_by',
      label: 'Ship By',
      isSortable: true,
      thStyle: {
        width: '12%',
      },
    },
    {
      key: 'return_by',
      label: 'Return by',
      isSortable: true,
      thStyle: {
        width: '12%',
      },
    },
  ]

  const quoteStatusBar = [
    {
      icon: 'LQuoteIconFill',
      title: 'Quote',
      iconSize: '26',
    },
    {
      icon: 'LSignatureIconFill',
      title: 'Approve',
      iconSize: '26',
    },
    {
      icon: 'LCreditCardIconFill',
      title: 'Charge',
      iconSize: '26',
    },
  ]

  const uploadAttachmentTableCol = [
    {
      label: 'File Name',
      thStyle: {
        width: '20%',
        fontWeight: 'bold',
      },
    },
    {
      label: 'Type',
      thStyle: {
        width: '15%',
        fontWeight: 'bold',
      },
    },
    {
      label: 'Category',
      thStyle: {
        width: '20%',
        fontWeight: 'bold',
      },
    },
    {
      label: 'Action',
      thStyle: {
        width: '25%',
        fontWeight: 'bold',
      },
    },
    {
      label: '',
      thStyle: {
        width: '30%',
        fontWeight: 'bold',
      },
    },
  ]

  // Store Mutations

  const storeMutationsList = [
    {
      mutation: 'SET_ORDERS_INFORMATION_FORM',
      payload: {},
    },
    {
      mutation: 'SET_CUSTOMER_INFORMATION_FORM',
      payload: {
        attachments: [],
      },
    },
    {
      mutation: 'SET_CUSTOMER_INFORMATION_PURCHASE_ORDER',
      payload: {},
    },
    {
      mutation: 'SET_INSURANCE_INFORMATION_FORM',
      payload: {},
    },
    {
      mutation: 'SET_DISPATCH_AND_RETURN_FORM',
      payload: {},
    },
    {
      mutation: 'SET_EVENT_INFORMATION_FORM',
      payload: {},
    },
    {
      mutation: 'SET_ORDER_ITEMS_RENTAL_TABLE',
      payload: {
        products: [],
      },
    },
    {
      mutation: 'SET_ORDER_ITEMS_SALES_TABLE',
      payload: {
        sales: [],
      },
    },
    {
      mutation: 'SET_ORDER_ITEMS_OTHER_CHARGES_TABLE',
      payload: {
        otherCharges: [],
      },
    },
    {
      mutation: 'SET_TOTALS_FORM',
      payload: {},
    },
    {
      mutation: 'SET_ON_ERROR',
      payload: {},
    },
    {
      mutation: 'SET_IS_SETTLED_CUSTOMER',
      payload: false,
    },
    {
      mutation: 'SET_IS_ORDER_INFORMATION_FORM_REQUIRED_FIELDS_FILLED',
      payload: false,
    },
    {
      mutation: 'SET_IS_EVENT_INFORMATION_REQUIRED_FIELDS_FILLED',
      payload: false,
    },
    {
      mutation: 'SET_IS_DISPATCH_AND_RETURN_ALL_REQUIRED_FIELDS_FILLED',
      payload: false,
    },
  ]

  // Methods === === === === =>
  const mapDate = date => {
    const getDate = new Date(date)

    return [getDate.getFullYear(), getDate.getMonth() + 1, getDate.getDate()].map(x => x.toString().padStart(2, '0')).join('-')
  }

  const mapTime = time => {
    if (!time) return ''

    return time.split(':').slice(0, 2).join(':')
  }

  const getProps = (fieldName, fields) => ({
    is: fields[fieldName].type,
    field: fields[fieldName],
    name: fieldName,
  })

  return {
    store,
    model,
    catalog,
    mapTime,
    mapDate,
    getProps,
    MODULE_NAME,
    tableColumns,
    quoteStatusBar,
    attachmentModel,
    CREATE_PAGE_TITLE,
    MODULE_NAME_CLONE,
    MODULE_NAME_CATALOG,
    storeMutationsList,
    MODULE_NAME_ATTACHMENT,
    uploadAttachmentTableCol,
    FORM_BUTTON_TITLE_SAVE_AS_DRAFT,
    DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT,
    DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PLACE_ORDER,
    DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL,
    DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_HOLD_STOCKS,
  }
}
