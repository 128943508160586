import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'
import { customActionGet } from '@core/utils/utils'
import { stringify } from 'qs'

// const endpoint = () => 'products'
const endpointSalesRentalQuote = () => 'orders/rental_sale'

const Actions = crudActions(endpointSalesRentalQuote)
const rentalSalesList = (_, queryParams) => customActionGet('orders/rental_sale', queryParams, _)
const getActiveAttachment = ({ commit }, { id, queryParams }) => customActionGet(`customers/${id}/attachments`, queryParams, commit, 'LIST_ATTACHMENTS')
const createSalesRentalQuote = (ctx, data) => axios.post(endpointSalesRentalQuote(), data)
const getOtherChargesList = () => (ctx, queryParams) => axios.get('/catalog/non-stocks', { params: queryParams })
const getCustomer = ({ }, { id, queryParams = {} }) => customActionGet(`customers/${id}`, queryParams)

const rentalOrders = (ctx, queryParams) => {
  if (!queryParams.hasOwnProperty('f')) {
    queryParams = { ...queryParams, ...{ f: { is_active: 1 } } }
  }
  return axios.get('/orders/rental/order-item', { params: queryParams, paramsSerializer: params => stringify(params) })
}
const saleOrders = (ctx, queryParams) => {
  if (!queryParams.hasOwnProperty('f')) {
    queryParams = { ...queryParams, ...{ f: { is_active: 1 } } }
  }
  return axios.get('/orders/sale/order-item', { params: queryParams, paramsSerializer: params => stringify(params) })
}
export default {
  ...Actions,
  createSalesRentalQuote,
  getCustomer,
  getActiveAttachment,
  endpointSalesRentalQuote,
  rentalSalesList,
  getOtherChargesList,
  rentalOrders,
  saleOrders,
}
